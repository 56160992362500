<template>
  <div class="btndefault-container" v-bind:class="rootClassName">
    <button class="btndefault-btnsee-more-button" :disabled="isDisabled">
      <span class="btndefault-text">{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Btndefault',
  props: {
    rootClassName: String,
    isDisabled:false,
    text: {
      type: String,
      default: '查看更多',
    },
  },
}
</script>

<style scoped>
.btndefault-container {
  display: flex;
  position: relative;
  cursor: pointer;
}
.btndefault-btnsee-more-button {
  cursor:pointer;
  gap: 10px;
  display: flex;
  padding: 11px 65px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3019607961177826) ;
  transition: 0.3s;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background-image: linear-gradient(159deg, rgba(1, 109, 237, 1) 0%, rgba(0, 178, 248, 1) 100%);
}
.btndefault-btnsee-more-button:hover {
  background-image: linear-gradient(159deg, rgb(1, 109, 237) 0.00%,rgb(35, 185, 244) 100.00%);
}
.btndefault-btnsee-more-button:disabled {
  background-image: linear-gradient(159deg, rgba(1, 109, 237, 0.35) 0.00%,rgba(0, 178, 248, 0.35) 100.00%);
}
.btndefault-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 18px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}



</style>
