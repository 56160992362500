<template>
  <div class="product-card-product-card" ref="card" :style="cssVars" v-on:mouseenter="()=>{
    this.isHover = true;
  }" v-on:mouseleave="()=>{
    this.isHover = false;
  }" >
    <div class="product-card-card-content">
      <img alt="logoI640" :src="Logo_Src" class="product-card-logo" />
      <div class="product-card-bottom-bar">
        <div class="product-card-left-text">
          <span class="product-card-text">{{ CardDescription }}</span>
          <span class="product-card-text1">{{ CardHeader }}</span>
        </div>
        <img alt="gotoIconI640" src="/arrow.svg" class="product-card-goto-icon" :class="{productCardHover:isHover}"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: 'ProductCard',
  props: {
    CardHeader: {
      type: String,
      default: 'AIGC图像生成平台',
    },
    CardDescription: {
      type: String,
      default: 'HUAHUA话画',
    },
    Logo_Src: {
      type: String,
      default: '/logo%20(2)-200h.png',
    },
    CardCover_src:{
      type: String,
      default: '/defaultProductCover.png',
    }
  },
  computed:{
    cssVars(){
      return {
        '--card-background-cover': 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0.00%,rgba(0, 0, 0, 0.68) 100.00%),url('+ this.CardCover_src +')'
      }
    }
  },
  // created(){
  //   //set background sytle to .product-card-product-card
  //   const card = ref("card");
  //   console.log(card);
  //   card.style.backgroundImage = this.backgroundImageStyle;
  // },
  data(){
    return{
      isHover:false
    }
  }
}
</script>

<style scoped>
.product-card-product-card {
  gap: 10px;
  width: 300px;
  display: flex;
  padding: 32px;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  max-width: 90vw;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.00%,rgba(0, 0, 0, 0.68) 100.00%),url("/compress_placeholder%202-600w.jpg"); */
  background-image: var(--card-background-cover) ;
}
.product-card-card-content {
  height: 317px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}
.product-card-logo {
  width: 67px;
  height: 67px;
}
.product-card-bottom-bar {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.product-card-left-text {
  gap: 3px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.product-card-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 36px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.4px;
  font-stretch: normal;
  text-decoration: none;
}
.product-card-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 24px;
  font-style: 65 Medium;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 500;
  line-height: 33.6px;
  font-stretch: normal;
  text-decoration: none;
}
 @media (max-width: 561px) {
  .product-card-text {
    font-size: 24px;
    line-height: 33.6px;
  }
  .product-card-text1 {
    font-size: 16px;
    line-height: 22.4px;
  }
} 
  

.product-card-goto-icon {
  width: 15px;
  height: 24px;
  opacity: 0;
  transition: 0.35s;
}
.productCardHover{
  transform: translateX(15px);
  opacity: 1;
}
@media(max-width: 991px) {
  .product-card-product-card {
    width: 575px;
  }
}
</style>
