<template>
  <div class="homepagedescription-first" :class="{reverseOrder:reverseOrderData}">
    <img :alt="image_alt" :src="image_src" class="homepagedescription-image" />
    <div data-aos="fade-up-right" class="homepagedescription-content">
      <div class="homepagedescription-list">
        <div class="homepagedescription-container">
          <span class="homepagedescription-text">{{ title }}</span>
          <underline-block></underline-block>
        </div>
        <span class="homepagedescription-text1">{{ description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import UnderlineBlock from './underlineBlock.vue'
export default {
  components: {
    UnderlineBlock,
  },
  name: 'Homepagedescription',
  props: {
    image_src: {
      type: String,
      default: '/compress_placeholder.jpg',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    title: {
      type: String,
      default: '示例标题',
    },
    description: {
      type: String,
      default:
        '示例内容',
    },
    reverseOrderData: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.underlineBlock{
  width: 100%;
  height: 3px;
  background-image: linear-gradient(90deg, #016DED 6%, #00B2F8 50%);
  border-radius: 3px;

}
.homepagedescription-first {
  width: 100%;
  height: 350px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
}
.reverseOrder{
  flex-direction: row-reverse;
}
.homepagedescription-image {
  width: 50%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}
.homepagedescription-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.homepagedescription-list {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.homepagedescription-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.homepagedescription-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 36px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.4px;
  font-stretch: normal;
  text-decoration: none;
}
.homepagedescription-text1 {
  color: #ffffff;
  font-style: normal;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 26px;
}
@media(max-width: 991px) {
  .homepagedescription-first {
    height: auto;
    flex-direction: column;
  }
  .homepagedescription-image {
    flex: 0 0 auto;
    width: 100%;
    height: 300px;
  }
  .homepagedescription-content {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .homepagedescription-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .homepagedescription-list {
    gap: var(--dl-space-space-unit);
  }
}
</style>
