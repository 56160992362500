<template>
  <div class="not-found-container" id="page-container">
    <div class="not-found-frame">
      <div class="not-found-page-content">
        <div class="not-found-hero-image">
          <div class="not-found-logo-description">
            <img
              alt="Logo6389"
              src="/external/t1-200h.png"
              class="not-found-logo"
            />
            <div class="not-found-description">
              <span class="not-found-text">页面未找到，正在返回首页</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'

export default {
  name: 'NotFound',
  components: {
    NavbarInteractive,

  },
  created() {
    setTimeout(() => {
      this.$router.push('/')
    }, 3000)
  },
  metaInfo: {
    title: 'NotFound - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'NotFound - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.not-found-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.not-found-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.not-found-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;
}
.not-found-hero-image {
  gap: 10px;
  min-height: 100vh;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/Placeholder.png");
}
.not-found-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-logo {
  width: 50vw;
  height: auto;
  max-width: 843px;
}
.not-found-description {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.not-found-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 40px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
.not-found-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.not-found-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-logo1 {
  width: 250px;
  height: 64px;
}
.not-found-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.not-found-text1 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.not-found-text3 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.not-found-text5 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
</style>
